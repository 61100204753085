export const environment = {
  name: 'staging',
  authEnv: 'qa',
  production: false,
  appUrl: 'https://onroute.staging.zonarsystems.net/',
  auth: {
    clientID: '7hmQLNIfkK8zr7fD2zq3KTGjReDUuOuU',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    applicationId: 'bac8ed0b-cce4-4f15-9e1a-20770a00452f',
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: '',
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
  },
  onRouteApi: {
    baseUrl: 'https://onroute-api.staging.zonarsystems.net/api',
  },
  hereApi: {
    calcRouteUrl: 'https://fleet.api.here.com/2/calculateroute.json',
    v8RoutesUrl: 'https://router.hereapi.com/v8/routes',
    geocodeUrl: 'https://geocode.search.hereapi.com/v1/geocode',
    revGeocodeUrl: 'https://revgeocode.search.hereapi.com/v1/revgeocode',
    autoCompleteUrl: 'https://autocomplete.search.hereapi.com/v1/autocomplete',
    apiKey: 'I3tUybcRpcAJvWIeBW2tX-uuoYjQeafA6tGbxtzh4KM',
    app_id: 'y6V9HTyWt0fjQNE4rjg1',
    app_code: '5NnwbLdL-vHSypcnbOGFVA',
  },
  i18n: {
    supportedLanguages: ['en', 'en-US'],
    defaultLanguage: 'en-US',
  },
  devCycle: {
    serverKey: 'dvc_client_d4a68300_a830_405b_9088_21f0b40e45f3_7f7717c',
  },
};
