export const permissions = {
  defaultRedirectUrl: '/',
  defaultPermPrefix: 'route_manager',
  routes: [['/', 'read:routes']],
};

export const datadog = {
  applicationId: '85a8ce4f-44a6-479f-bd02-0ae82425a8b1',
  clientToken: 'pubcda854029b683ed108ff9ae96709d83f',
  site: 'us5.datadoghq.com',
};

export const appName = 'onroute-web';
export const appVersion = '0.17.2';
